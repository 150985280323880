import React, { useEffect } from "react"
import { Router } from "@reach/router"
import { connect } from "react-redux"
import loadable from "@loadable/component"

import SuspenseLoading from "../components/SuspenseLoading"
import { setLocation } from "../actions/setLocation"

const CollectionsPage = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ "../components/Collections/CollectionsPage"
    ),
  { fallback: <SuspenseLoading /> }
)

// import CollectionsPage from "../components/Collections/CollectionsPage"

const Collections = ({ dispatch }) => {
  //Set the site location.
  useEffect(() => {
    dispatch(setLocation("/shop"))
  }, [dispatch])

  return (
    <Router basepath="/shop">
      <CollectionsPage path=":collectionHandle" />
    </Router>
  )
}

export default connect()(Collections)
